import { gql } from '@apollo/client';

export const navigationMenu = gql`
  fragment NavigationFields on NavigationMenu {
    title
    linkUrl
    childNavigationItems {
      title
      linkUrl
    }
  }
`;

export const GET_NAVIGATION_MENUS = gql`
  query GetNavigationMenus {
    getNavigationMenus {
      ...NavigationFields
    }
  }
  ${navigationMenu}
`;
