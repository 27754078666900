import React, { createContext, useEffect, useState } from 'react';
import { Ability } from '@casl/ability';
import PropTypes from 'prop-types';
import { defineAbilityFor } from '../utils/user-ability';
import useCurrentUser from '../hooks/use-current-user';

export const AbilityContext = createContext();

const AbilityProvider = ({ children }) => {
  const [ability, setAbility] = useState(null);
  const userCtx = useCurrentUser();

  useEffect(() => {
    const setUserAbility = async () => {
      if (userCtx && userCtx.currentUser) {
        const { abilities: rules } = userCtx.currentUser;
        if (rules) {
          const userAbility = await defineAbilityFor(rules);
          setAbility(userAbility);
        }
      }
    };
    setUserAbility();
  }, [userCtx]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

AbilityProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AbilityProvider;
