import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import useCurrentUser from '../use-current-user';
import { GET_NAVIGATION_MENUS } from './data-layer';
import { getFormattedMenu } from './helpers';

const useNavigationMenu = () => {
  const { currentUser, isLoggedIn } = useCurrentUser();
  const [getMenu, { data }] = useLazyQuery(GET_NAVIGATION_MENUS);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (currentUser && isLoggedIn) {
      getMenu();
    } else {
      setMenu([]);
    }
  }, [currentUser, isLoggedIn]);

  useEffect(() => {
    if (data && data.getNavigationMenus) {
      setMenu(getFormattedMenu(data.getNavigationMenus));
    }
  }, [data]);

  return menu;
};

export default useNavigationMenu;
