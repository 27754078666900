import { Hub } from 'aws-amplify';
import useLocalStorage from './use-local-storage';
import {
    startPollingCookie,
    stopPollingCookie,
    setCookie,
    deleteCookies
} from '../utils';

const authEventName = {
    signIn: 'signIn',
    signOut: 'signOut'
};
export const userSessionKey = 'user-session';

export const sessionStatus = {
    loggedOut: 'loggedOut',
    loggedIn: 'loggedIn',
    expiring: 'expiring'
};

const listeners = {};

export default ({defaultOnLoggedOut} = {}) => {
    const {
        loggedOut,
        loggedIn,
        expiring
    } = sessionStatus;
    const {
        storage,
        listenStorage,
        stopListenStorage
    } = useLocalStorage();

    const pipe = (...fns) => (withValue) =>
        fns.reduce((value, fn) => {
            if (fn) fn(value);
            return value;
        }, withValue);

    const cleanUpStorage = () => {
        deleteCookies(/^CognitoIdentityServiceProvider/);
        storage.remove(userSessionKey);
    };

    const handleStatusChange = (status) => {
        let fns = listeners[status];
        if (status === loggedOut) {
            stopObserveSessionStatus();
            cleanUpStorage();
            if ((!fns || !fns.length) && defaultOnLoggedOut) {
                fns = [defaultOnLoggedOut];
            }
        }
        if (fns) pipe(...fns)();
    };

    let storageHanlderId;
    const handleStorageEvents = ({ key, oldValue, newValue }) => {
        if (key === userSessionKey && 
            newValue !== oldValue) {
            handleStatusChange(newValue, oldValue);
        }
    };

    const observeSessionStatus = () => {
        storageHanlderId = listenStorage(handleStorageEvents);
        startPollingCookie(userSessionKey, (status) => {
            storage.set(userSessionKey, status);
        });
    };

    const stopObserveSessionStatus = () => {
        stopPollingCookie();
        stopListenStorage(storageHanlderId);
    };

    const addListener = (status) => {
        return (fn) => {
            const fns = listeners[status] || [];

            let idx = fns.indexOf(fn);
            if (idx > -1) return idx;
            
            // reuse listeners status callbacks pool
            idx = fns.indexOf(undefined);
            if (idx > -1) {
                fns[idx] = fn;
            } else {
                idx = fns.push(fn) - 1;
            }

            listeners[status] = fns;

            return idx;
        };
    };

    const stopListener = (idx, status) => {
        const fns = listeners[status];
        if (!fns || !fns.length) return;

        fns.splice(idx, idx, undefined);
    };

    const setStatus = (status) => {
        return () => {
            storage.set(userSessionKey, status);
            setCookie(userSessionKey, status);
            handleStatusChange(status);
        }
    };

    // FIX temporary check to resolve issue with home-app unit tests
    if (Hub) {
        Hub.listen('auth', ({payload}) => {
            const { event } = payload;
            const { signIn, signOut } = authEventName
            if (event === signIn) {
                setStatus(loggedIn)();
            }
            if (event === signOut) {
                setStatus(loggedOut)();
            }
        });
    }

    const getCurrentStatus = () => {
        return storage.get(userSessionKey);
    };

    return {
        setLoggedIn: setStatus(loggedIn),
        setExpiring: setStatus(expiring),
        setLoggedOut: setStatus(loggedOut),
        onLoggedIn: addListener(loggedIn),
        onExpiring: addListener(expiring),
        onLoggedOut: addListener(loggedOut),
        stopSessionEventListener: stopListener,
        observeSessionStatus,
        stopObserveSessionStatus,
        getCurrentStatus,
    };
};