import { storageAvailable, type } from '../utils/web-storage';

const listeners = []; // [fn1, fn2, ..., fnn]

export default () => {
  // TODO alternative solution when not available
  if (!storageAvailable(type.local)) return {};

  const storageAction = {
    set: 'set',
    get: 'get',
    remove: 'remove',
  };

  const pipe = (...fns) => (withValue) =>
  fns.reduce((value, fn) => {
    if (fn) fn(value);
    return value;
  }, withValue);

  const stopListenStorage = (idx) => {
    const fn = listeners[idx];
    if (!fn) return;

    window.removeEventListener('storage', fn);
    listeners[idx] = undefined;
  };

  const listenStorage = (fn) => {
    if (listeners.indexOf(fn) > -1) return;
    
    window.addEventListener('storage', fn);

    const idx = listeners.indexOf(undefined);
    if (idx > -1) {
      listeners[idx] = fn;
      return idx;
    }
    
    return listeners.push(fn) - 1;
  };
  
  return {
    listenStorage,
    storage: {
      get: (key) => {
        return localStorage.getItem(key);
      },
      set: (key, value) => {
        try {
          const oldValue = localStorage.getItem(key);
          localStorage.setItem(key, value);
          pipe(...listeners)({key, newValue: value, oldValue});

        } catch (error) {
          // TODO propagate error
          console.log(`LocalStorage set error: ${error}`);
        }
      },
      remove: (key) => {
        const oldValue = localStorage.getItem(key);
        localStorage.removeItem(key);
        pipe(...listeners)({key, newValue: undefined, oldValue});
      },
    },
    storageAction,
    stopListenStorage,
  };
};