import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import {
  currentAuthenticatedUser,
  getToken,
  logout,
  loginUserExternal,
  loginUserGSA,
} from '../utils/auth-services';
import useUserSessionStatus from '../hooks/use-user-session-status';

export const CURRENT_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      customerAttrs {
        registeredAgencyCode
        registeredBureauCode
        registeredOfficeCode
      }
      abilities
      roles
      status {
        id
        name
      }
    }
  }
`;

export const UserContext = createContext();

export default function UserProvider({ children, loggedOutUserPath }) {
  const history = useHistory();
  const [authDat, setAuthDat] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { onLoggedOut, observeSessionStatus } = useUserSessionStatus({
    defaultOnLoggedOut,
  });

  function defaultOnLoggedOut() {
    window.location.assign(loggedOutUserPath);
  }

  const idleTimeout = window.AFP_CONFIG.inactivity_timer_minutes
    ? window.AFP_CONFIG.inactivity_timer_minutes * 60000
    : 30 * 60000;
  let idleTimeoutChecker;
  const { getLastActiveTime } = useIdleTimer({
    idleTimeout,
  });

  const [getMe] = useLazyQuery(CURRENT_USER, {
    fetchPolicy: 'network-only',
    onError(error) {
      const { graphQLErrors = [] } = error;
      const unauthenticatedError = graphQLErrors.some(
        ({ extensions }) => extensions && extensions.code === 'UNAUTHENTICATED',
      );
      if (unauthenticatedError) {
        setAuthError({ error: error.message, code: 'UNAUTHENTICATED' });
        setIsLoading(false);
      }
    },
    onCompleted: (data) => {
      // Upon successful load of /Me set it to the context state (currentUser)
      const { me } = data;

      if (me) {
        const { __typename, ...rest } = me;
        // Merge oAuth data with profile data.

        //const status = { id:4 };
        setCurrentUser((preVal) => ({ ...preVal, ...rest }));
        setIsLoggedIn(true);
        setIsLoaded(true);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const oAuthData = await currentAuthenticatedUser();
        setAuthDat(oAuthData);
        setCurrentUser(oAuthData);
        setIsAdmin(false);
      } catch (err) {
        setAuthError({ error: err.message, code: 'NOSESSION' });
        setIsLoading(false);
      }
    };
    checkAuth();
    observeSessionStatus();
  }, []);

  // Auth provider has successfully authenticated user.
  // Go grab user profile. // Me/
  useEffect(() => {
    if (authDat?.id) {
      getMe();
    }
  }, [authDat, getMe]);

  return (
    <UserContext.Provider
      value={{
        isLoaded,
        isLoggedIn,
        isLoading,
        authError,
        isAdmin,
        currentUser,
        logout,
        onLoggedOut,
        loggedOutUserPath,
        getToken,
        loginUserExternal,
        loginUserGSA,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const defaultLoggedOutUserPath =
  (window.AFP_CONFIG &&
    window.AFP_CONFIG.appURLs &&
    window.AFP_CONFIG.appURLs.home) ||
  '/';
UserProvider.defaultProps = {
  loggedOutUserPath: defaultLoggedOutUserPath,
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
  loggedOutUserPath: PropTypes.string,
};
