import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { logout } from '../utils/auth-services';
import { Modal, connectModal, useModal, Button } from '@gsa/afp-component-library';
import { useUserSessionStatus, useUserInactivity } from '../hooks';
import { sessionStatus } from '../hooks/use-user-session-status';

const Title = () => {
  return <h2>Are you still with us?</h2>;
};

const Content = () => {
  return (
    <p>
      For your security, your session is about to expire in <b>5 minutes</b> due to
      inactivity. If you select the &lsquo;Log out&rsquo; button or do not
      respond, your session will automatically close. Do you want to stay signed
      in?
    </p>
  );
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <React.Fragment>
      <Button
        variant="unstyled margin-right-2 text-no-underline"
        type="button"
        onClick={onCancel}
        data-testid="session-logout-btn"
      >
        Log out
      </Button>
      <Button
        variant="primary"
        type="button"
        data-testid="session-keep-signed-btn"
        onClick={onConfirm}
      >
        Yes, keep me signed in
      </Button>
    </React.Fragment>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const PromptSessionExpiring = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const {
    setExpiring,
    setLoggedIn,
    onExpiring,
    onLoggedIn,
    stopSessionEventListener,
    getCurrentStatus
  } = useUserSessionStatus();
  const { startIdleTimer, pauseIdleTimer } = useUserInactivity({
    onIdle: onUserIdle
  });

  function onUserIdle() {
    setExpiring();
  }

  const onCancel = () => {
    closeModal();
    logout();
  };

  const onConfirm = () => {
      closeModal();
      setLoggedIn();
  };

  
  let expiringTimeout = 5 * 1000 * 60; // 5 minutes
  if (window.AFP_CONFIG && 
      window.AFP_CONFIG.expiring_session_timer_minutes) {
      expiringTimeout = window.AFP_CONFIG.expiring_session_timer_minutes * 1000 * 60;
  }

  let expiringTimedoutHandlerId;
  const startExpiringTimeout = (handler, timeout) => {
    return setTimeout(handler, timeout);
  };
  const stopExpiringTimeout = (id) => {
    clearTimeout(id);
  };

  let expiringHandlerId;
  let loggedInHandlerId;
  const handleExpiring = () => {
    pauseIdleTimer();
    openModal();
    if(!expiringTimedoutHandlerId) {
      expiringTimedoutHandlerId = startExpiringTimeout(onCancel, expiringTimeout);
    }
  };
  const handleLoggedIn = () => {
    startIdleTimer();
    closeModal();
    stopExpiringTimeout(expiringTimedoutHandlerId);
    expiringTimedoutHandlerId = undefined;
  };

  useEffect(() => {
    const currentStatus = getCurrentStatus();
    if(currentStatus === sessionStatus.expiring) {
      openModal();
    }

    expiringHandlerId = onExpiring(handleExpiring);
    loggedInHandlerId = onLoggedIn(handleLoggedIn);

    if (currentStatus === sessionStatus.loggedIn) {
      handleLoggedIn();
    }

    return () => {
      stopSessionEventListener(expiringHandlerId, sessionStatus.expiring);
      stopSessionEventListener(loggedInHandlerId, sessionStatus.loggedIn);
    };
  }, []);

  const PromptModal = connectModal(() => (
    <Modal
      className="usa-prose"
      title={<Title />}
      actions={
        <Actions 
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      }
      onClose={onCancel}
    >
      <Content />
    </Modal>
  ));

  return <PromptModal isOpen={isOpen} />;
};

export default PromptSessionExpiring;