import Amplify, { Auth } from 'aws-amplify';
import localStorage from './local-storage';

const { removeItem, setItem } = localStorage;
const federationConfig =  window.AFP_CONFIG && window.AFP_CONFIG.cognito_federation_config


if (window.AFP_CONFIG && window.AFP_CONFIG.cognito_config) {
  Amplify.configure(window.AFP_CONFIG.cognito_config);
} else {
  console.warn(
    'Configuration error: window.AFP_CONFIG.cognito_config must be defined to use auth-services',
  );
}

const formUserObject = (authResp) => {
  const { payload } = authResp.signInUserSession.idToken;
  let id = '';
  if (payload.identities) {
    id = payload.identities[0].userId;
  } else {
    id = payload['cognito:username'];
  }
  const user = {
    id,
    email: payload.email,
    name: `${payload.given_name} ${payload.family_name}`,
    roles: payload['cognito:groups'],
    token: authResp.signInUserSession.accessToken.jwtToken,
  };
  return user;
};

export const currentAuthenticatedUser = async () => {
  try {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return formUserObject(loggedInUser);
  } catch (err) {
    console.log('Error : ', err);
    throw new Error('Error on get current user (currentAuthenticatedUser)');
  }
};

/**
 * @deprecated Will get deprecated in next version (2.0)
 * Will be deleted in version 3.0.
 * @param {*} redirectProps
 * @returns User object
 */
export const checkLogin = async (redirectProps) => {
  window.LOG_LEVEL = 'DEBUG';
  try {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return formUserObject(loggedInUser);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error on get current user (checkLogin)', e);
  }

  if (redirectProps && redirectProps !== null) {
    setItem('redirectProps', redirectProps);
  }
  try {
    await Auth.federatedSignIn(federationConfig);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error logging in (checkLogin)', e);
  }

  return undefined;
};

export const logout = async () => {
  try {
    removeItem('redirectProps');
    await Auth.signOut();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error signout', e);
  }
};

const login = async (provider) => {
  try {
    await Auth.federatedSignIn({ provider });
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
     // eslint-disable-next-line no-console
    console.log('Error sign in', e);
    throw e;
  }
};

export const loginUserGSA = ()=> login(federationConfig.gsaIdp);
export const loginUserExternal = ()=> login(federationConfig.externalIdp);


export const getToken = async () => {
  const session = await Auth.currentSession();
  return session.accessToken.jwtToken;
};
