import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Spinner } from '@gsa/afp-component-library';
import useCurrentUser from '../hooks/use-current-user';
import useAppAbility from '../hooks/use-app-ability';
import UserStatus from '../utils/user-status';

const PrivateRoute = ({
  component: Component,
  loggedOutUserPath,
  operation,
  subject,
  ...rest
}) => {
  const {
    isLoaded,
    isLoggedIn,
    isLoading,
    isAdmin,
    authError,
    currentUser    
  } = useCurrentUser();
  const location = useLocation();
  const ability = useAppAbility();
    
  const isPage = (pageType) => location.pathname.toLowerCase().indexOf(pageType.toLowerCase()) > 0;

  if (isLoading || !ability) return <Spinner className="padding-y-9" />;

  // User is logged out. No active cognito session
  if (authError?.code === 'NOSESSION') {
    // Redirect to public home page
    return <Redirect to={{ pathname: loggedOutUserPath }} />;
  }

  // Redirects to unauthorized page if there is a GraphQL 'UNAUTHENTICATED' error.
  if (authError?.code === 'UNAUTHENTICATED') {
    // Redirect to unauthorized page
    return <Redirect to={{ pathname: '/unauthorized' }} />;
  }

  if (operation && subject) {
    if (!ability.can(operation, subject)) {
      return <Redirect to={{ pathname: '/unauthorized' }} />;
    }
  }

  if(isLoggedIn){
    if(!currentUser || !currentUser.status || !currentUser.status.id){
      return <Redirect to={{ pathname: '/unauthorized' }} />;
    }
    const userStatus = parseInt(currentUser?.status?.id);    
    switch(userStatus){
      case UserStatus.Active:
        break;      
      case UserStatus.PendingReactivation:
        if(!isPage('request-submitted'))
          return <Redirect to={{ pathname: '/request-submitted' }} />;
        break;
      case UserStatus.Inactive:
        if(!isPage('inactive'))
          return <Redirect to={{ pathname: '/inactive' }} />;
        break;
      case UserStatus.PendingProfile:
      case UserStatus.PendingRole:
        if(!isPage('profile'))
          return <Redirect to={{ pathname: '/profile' }} />;
        break;
      case UserStatus.Deleted:
      case UserStatus.Denied:      
      case UserStatus.Deactivated:          
      default:
        return <Redirect to={{ pathname: '/unauthorized' }} />;
    }
  }

  if (!isLoaded) {
    return null;
  }

  if (isLoaded && !isLoggedIn) {
    return <Redirect to={{ pathname: loggedOutUserPath }} />;
  }

  if (isLoaded && isPage('manage') && !isAdmin) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PrivateRoute.defaultProps = {
  loggedOutUserPath: '/',
  operation:undefined,
  subject:undefined,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  loggedOutUserPath: PropTypes.string,
  operation: PropTypes.string,
  subject: PropTypes.string,
};

export default PrivateRoute;
