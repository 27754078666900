const isNull = (val) => val === null;

const getUrlFromLink = (linkUrl) => {
  const { appURLs } = window.AFP_CONFIG || {};

  const appUrlRegex = /[^{.\}]+(?=})/g;
  const urlRegex = /(?<=}).*/g;

  const appUrl = (appUrlRegex.exec(linkUrl) || [])[0];
  const url = (urlRegex.exec(linkUrl) || [])[0];

  if (!(isNull(appUrl) && isNull(linkUrl))) {
    return `${appURLs[appUrl]}${url}`;
  }

  return `${appURLs[appUrl]}`;
};

export const getFormattedMenu = (getNavigationMenus) => {
  const menu = getNavigationMenus.map((menuItem) => {
    if (menuItem.childNavigationItems?.length > 0) {
      return {
        sectionTitle: menuItem.title,
        items: menuItem.childNavigationItems.map((item) => ({
          linkText: item.title,
          link:
            item.linkUrl !== '#not_implemented'
              ? getUrlFromLink(item.linkUrl)
              : item.linkUrl,
        })),
      };
    }
    return {
      sectionTitle: menuItem.title,
      link:
        menuItem.linkUrl !== 'null'
          ? getUrlFromLink(menuItem.linkUrl)
          : '#not_implemented',
    };
  });

  return menu;
};

export default {
  getFormattedMenu,
};
